<template>
	<div class="bg-cover bg-center bg-no-repeat bg-white" style="background-image: url('https://cecosarl.com/images/hero-carousel-4.jpg')">
		<div class="w-full h-64 bg-black bg-opacity-50  justify-between p-5">
			
			<div class="mx-auto flex px-2 justify-between items-center text-white">
				<div class="container flex justify-between items-center  relative bg-cover bg-center">
					
					<!-- Logo -->
					<div class="flex items-center">
						<router-link to="/">
							<h2 class="font-bold xs:text-2xl md:text-4xl text-white">CECO Sarl</h2>
						</router-link>
					</div>

					<!-- Menu -->
					<nav class="xs:hidden lg:flex sm:ml-48 space-x-5 text-white">
						<router-link to="/" class="hover:text-custom-yellow">Accueil</router-link>
						<router-link :to="{ name: 'service'}" class="hover:text-custom-yellow">Services</router-link>
						<router-link :to="{ name: 'project'}" class="hover:text-custom-yellow">Projets</router-link>
						<router-link :to="{ name: 'blogs'}" class="hover:text-custom-yellow">Blog</router-link>
						<router-link :to="{ name: 'about'}" class="hover:text-custom-yellow">A propos</router-link>
						<router-link :to="{ name: 'contact'}" class="hover:text-custom-yellow">Contact</router-link>
					</nav>
					
				</div>
				<div class="mx-2">
					<!-- Search icon --> 
					<div class="relative flex space-x-8 text-white">
						<button v-if="!showMenu" @click="menuModal" class="focus:outline-none">
							<i class="fas fa-bars"></i>
						</button>
					</div>
				</div>
			</div>
			<div v-if="showMenu" class="absolute border-blue-500 shadow-md bg-black bg-opacity-20 text-white z-10 right-0 xs:w-36 md:w-62 px-5 px-5 p-2 md:py-5 h-auto bg-white top-0">
				<div class="flex">

					<div class="">
						<button v-if="showMenu" class="focus:outline-none" @click="menuModal" style="color:#DC3545;">
							<i class="fa fa-xmark"></i> 
						</button>
					</div>
				</div>
				
				<ul class="mt-5">
					<li class="mt-4 ">
						<router-link to="/" class=" hover:text-custom-yellow">Accueil</router-link>
					</li>
					<li class="mt-4 ">
						<router-link :to="{ name: 'service'}" class="hover:text-custom-yellow">Services</router-link>
					</li>
					
					<li class="mt-4 ">
						<router-link :to="{ name: 'project'}" class="hover:text-custom-yellow">Projets</router-link>
					</li>
					
					<li class="mt-4 ">
						<router-link :to="{ name: 'blogs'}" class="hover:text-custom-yellow">blog</router-link>
					</li>

					<li class="mt-2 ">
						<router-link :to="{ name: 'about'}" class=" hover:text-custom-yellow">A propos</router-link>
					</li>
					<li class="mt-2 ">
						<router-link :to="{ name: 'contact'}" class="hover:text-custom-yellow">Contact</router-link>
					</li>
				</ul>

				

			</div>  

				<div class="flex item-center w-full mt-10">
					<h2 class="mx-auto font-bold text-2xl text-white">{{ titleMessage  }}</h2>
				</div>
				<div class="flex item-center w-full mt-5">

					<ol class="mx-auto text-white">
						<li><router-link to="/">Accueil</router-link> / <span class="text-custom-yellow">{{  titleMessage }}</span></li>
					</ol>
				</div>
			
		</div>
	</div>
</template>

<script>
/* eslint-disable */

export default {
  name: "HeaderComponent",

  data() {
    return {
      showSearch: false,
      showMenu: false
    };
  },

  props: {
	titleMessage: String
  },

  methods: {
    searchModal() {
      this.showSearch = !this.showSearch;
    },

    menuModal() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>

<!-- "scoped" attribute to limit CSS to this component only -->
<style>
</style>

