<template>
    <div class="px-5 md:px-10 md:px-10 py-14 w-full">
        <div class="w-full">
            <div class="flex items-center font-bold xs:text-2xl md:text-4xl">
                <h2 class="mx-auto relative border-b-4 pb-2 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Constructions</h2>
            </div>
            <div class="flex items-center mt-5">
                <p class="mx-auto relative">Présentation de projets clés réalisés</p>
            </div>
            
            <div class="md:grid md:grid-cols-2 justify-between gap-5 mt-14 px-5 md:px-14">
                <div class="md:flex gap-5 justify-between border">
                    <div class="md:w-2/4 md:p-5">
                        <img class="w-full" src="https://cecosarl.com/images/construction/construction-1.jpg" alt="Construction d'un centre commercial high-tech">
                    </div>
                    <div class="md:w-2/4 p-5">
                        <h3 class="font-bold text-lg">Construction d'un centre commercial high-tech</h3>
                        <p class="mt-2">Fuga in dolorum et iste et culpa. Commodi possimus nesciunt modi voluptatem placeat deleniti adipisci. Cum delectus</p>
                    </div>
                </div>

                <div class="md:flex gap-5 xs:mt-5 md:mt-0 justify-between border">
                    <div class="md:w-2/4 md:p-5">
                        <img class="w-full" src="https://cecosarl.com/images/construction/construction-2.jpg" alt="Rénovation d'un hôpital historique">
                    </div>
                    <div class="md:w-2/4 p-5">
                        <h3 class="font-bold text-lg">Rénovation d'un hôpital historique</h3>
                        <p class="mt-2">Fuga in dolorum et iste et culpa. Commodi possimus nesciunt modi voluptatem placeat deleniti adipisci. Cum delectus </p>
                    </div>
                </div>
            <!-- </div>

            <div class="md:flex justify-between gap-5 mt-5 px-5 md:px-14"> -->
                <div class="md:flex xs:mt-5 md:mt-0 gap-5 justify-between border">
                    <div class="md:w-2/4 md:p-5">
                        <img class="w-full" src="https://cecosarl.com/images/construction/construction-3.jpg" alt="Aménagement d'une autoroute durable">
                    </div>
                    <div class="md:w-2/4 p-5">
                        <h3 class="font-bold text-lg">Aménagement d'une autoroute durable</h3>
                        <p class="mt-2">Fuga in dolorum et iste et culpa. Commodi possimus nesciunt modi voluptatem placeat deleniti adipisci. Cum delectus</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: 'ConstructionComponet'
    }

</script>

<style>

</style>