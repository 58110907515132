<template>
    <div>
        <Header-component :titleMessage="indice"></Header-component>

        <div>
			<div class="w-full">
				
				<div class="px-5 md:px-24">
					
				</div>

			
                <ServiceComponent></ServiceComponent>

                <ConstructionComponent></ConstructionComponent>

                <AdvantageComponent></AdvantageComponent>
                
                <ArticlesComponent></ArticlesComponent>

            


				<footer-component></footer-component>
				

			</div>
		</div>
    </div>

</template>

<script>

    /* eslint-disable */
    import HeaderComponent from "../../src/components/HeaderComponent.vue";
    import FooterComponent from "../../src/components/FooterComponent.vue";
    import ServiceComponent from "../../src/components/ServiceComponent.vue";
    import AdvantageComponent from "../../src/components/AdvantageComponent.vue";
    import ArticlesComponent from "../../src/components/ArticlesComponent.vue";
    import ConstructionComponent from "../../src/components/ConstructionComponent.vue";

    
    export default {
        name: "ServiceView",
        components: { HeaderComponent, FooterComponent, ServiceComponent, AdvantageComponent, ArticlesComponent, ConstructionComponent },

        data(){
            return{
                indice: 'Service'
            }
        }
    }
    
</script>

<style>

</style>