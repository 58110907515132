<template>
    <div class="px-5 md:px-10 md:px-10 py-14 w-full">
        <div class="w-full">
            <div class="flex items-center font-bold xs:text-2xl md:text-4xl">
                <h2 class="mx-auto relative border-b-4 pb-2 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Projets</h2>
            </div>
            <div class="flex items-center mt-5">
                <p class="mx-auto relative">Nos projets réalisés</p>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-5 p-5 md:px-14">
                <div v-for="project in projects" :key="project.id" class="h-86 mt-5 md:mt-14 bg-cover bg-center bg-no-repeat bg-white justify-between p-5" :style="{ backgroundImage: `url(${handleImage(project.image)})` }">
                    <div class="w-1/3 bg-custom-yellow text-white p-1 text-center">
                        {{ project.category ? project.category.name : ''}}
                    </div>
                    <div class="mt-48 text-white bg-black bg-opacity-50 p-5">
                        <h3 class="font-bold text-lg border-b-4 border-gray-100 hover:border-b-4 hover:border-custom-yellow">{{ project.name }}</h3>
                        <p class="mt-5">{{ project.description }}</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

import { getProjects } from "../jscore/init.js";
import { getProfileImageUrl } from "../jscore/ImageHandler.js";

export default {
  name: "ProjectComponent",

  data() {
    return {
      projects: {}
    };
  },

  mounted() {
    this.fetchProjects();
  },

  methods: {
    fetchProjects() {
      getProjects()
        .then(response => {
          this.projects = response.data.project;
        })
        .catch(error => {
          console.log(error);
        });
    },

    //image handler
    handleImage(path) {
      const image = getProfileImageUrl(path);
      return image;
    }
  }
};
</script>


<style>
</style>