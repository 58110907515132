<template>
    <div>
        <Header-component :titleMessage="indice"></Header-component>

		<div>	
			<ProjectComponent></ProjectComponent>
		</div>

		<ArticlesComponent></ArticlesComponent>

		<footer-component></footer-component>

    </div>

</template>

<script>

    /* eslint-disable */
    import HeaderComponent from "../../src/components/HeaderComponent.vue";
    import FooterComponent from "../../src/components/FooterComponent.vue";
    import ProjectComponent from "../../src/components/ProjectComponent.vue";
    import ArticlesComponent from "../../src/components/ArticlesComponent.vue";


    export default {
        name: "ProjectView",
        components: { HeaderComponent, FooterComponent, ProjectComponent, ArticlesComponent },

		data(){
			return{
				indice: 'Projet'
			}
		}
    }
    
</script>

<style>

</style>