import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import ContactView from '../views/ContactView.vue';
import ServiceView from '../views/ServiceView.vue';
import ProjectView from '../views/ProjectView.vue';
import ArticlesView from '../views/ArticlesView.vue';
import ArticleView from '../views/ArticleView.vue';
import NotFoundView from '../views/NotFoundView.vue'; // Import the NotFoundView component

const routes = [
  { path: '/', component: HomeView, name: "home" },
  { path: '/about', component: AboutView, name: "about" },
  { path: '/contact', component: ContactView, name: "contact" },
  { path: '/services', component: ServiceView, name: "service" },
  { path: '/projects', component: ProjectView, name: "project" },
  { path: '/blogs', component: ArticlesView, name: "blogs" },
  { path: '/blog/:slug', component: ArticleView, name: "blog" },

  // Catch-all route for 404
  { path: '/:pathMatch(.*)*', component: NotFoundView, name: "not-found" }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
