<template>
    <div class="px-5 md:px-10 md:px-10 py-14 w-full bg-gray-100">
        <div class="w-full">
            <div class="flex items-center font-bold xs:text-2xl md:text-4xl">
                <h2 class="mx-auto relative border-b-4 pb-2 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Articles Récents</h2>
            </div>
            <div class="flex items-center mt-5">
                <p class="mx-auto relative">In commodi voluptatem excepturi quaerat nihil error autem voluptate ut et officia consequuntu</p>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-5 mt-5 md:mt-14 p-5 md:px-14">
                <div v-for="article in articles" :key="article.id" class="">
                    <div class="w-full h-70vh">
                        <router-link :to="{name: 'blog', params: { slug: article.slug}}">
                            <img class="" :src="handleImage(article.image)" alt="">
                        </router-link>
                    </div>
                    <div class="p-5 border">
                            <h3 class="font-bold text-xl hover:text-custom-yellow">
                                <router-link :to="{name: 'blog', params: { slug: article.slug}}">                                
                                    {{ article.title }}
                                </router-link>
                            </h3>

                            <div class="flex border-b border-gray-200 mt-5 pb-5 w-full justify-between">
                                <p class="text-gray-600"><i class="fas fa-user text-xs text-custom-yellow"></i> <span class="mt-2">{{ article.author }}</span></p>
                                <p v-if="article.category" class="text-gray-400 bg-custom-yellow text-white p-1">
                                        {{ article.category.name }}
                                </p>
                            </div>
                            <p class="mt-2 hover:text-custom-yellow hover:cursor-pointer">Lire plus <i class="fas fa-arrow-right text-custom-yellow"></i></p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>

/* eslint-disable */

import { getArticles } from "../jscore/init.js";
import { getProfileImageUrl } from "../jscore/ImageHandler.js";

export default {
  name: "ArticlesComponent",

  data() {
    return {
        articles: {},

    };
  },

  
  mounted(){
    this.fetchArticles()
  },

  methods: {
    fetchArticles(){

        getArticles()
        .then(response => {
            this.articles = response.data.blog;
        })
        .catch(error => {
            console.log(error);
        });
    },

    //image handler
    handleImage(path){
        const image = getProfileImageUrl(path);
        return image;
    },
}
};

</script>

<style>

</style>