<template>
    <div>
        <Header-component :titleMessage="indice"></Header-component>

		<div v-if="article" class="px-5 xs:px-15 md:px-10 py-14 w-full bg-gray-100">
            <div class="w-full">
                <div class="flex items-center font-bold xs:text-2xl md:text-4xl">
                    <h2 class="mx-auto relative border-b-4 pb-2 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Articles Récents</h2>
                </div>
                <div class="flex items-center mt-5">
                    <p class="mx-auto relative">In commodi voluptatem excepturi quaerat nihil error autem voluptate ut et officia consequuntu</p>
                </div>

                <div class="flex w-full mt-5 md:mt-14 p-5 xs:px-0 md:px-10">
                    <div class="mx-auto md:w-2/3">
                        <div class="w-full">
                            <img class="w-full" :src="handleImage(article.image)" alt="">
                        </div>
                        <div class="xs:px-5 md:px-10 py-5 border">
                            <h3 class="font-bold text-2xl">{{ article.title }}</h3>
                            <div class="flex border-b border-gray-200 mt-5 pb-5 w-full justify-between">
                                <p class="text-gray-600"><i class="fas fa-user text-xs text-custom-yellow"></i> <span class="mt-2">{{ article.author }}</span></p>
                                <p v-if="article.category" class="text-gray-400 bg-custom-yellow text-white p-1">{{ article.category.name }}</p>
                            </div>
                            <p class="mt-5">{{ article.description }}</p>
                        </div>
                    </div>
                        
                </div>

                
            </div>
        </div>
        <div v-if="!article" class="flex items-center">
            <div class="mx-auto px-44 py-44">
                <h1 class="text-red-500 text-4xl">Article Inconnu</h1>
                <p class="mt-10">Désolé, Cet article n'existe pas.</p>
                <div class="mt-10">
                    <router-link to="/" class="bg-custom-yellow text-white rounded-full px-2 py-2">Retour <i class="fas fa-arrow-right"></i> </router-link>
                </div>
            </div>
        </div>
        
		<ServiceComponent></ServiceComponent>

		<FooterComponent></FooterComponent>

    </div>

</template>

<script>

    /* eslint-disable */
    import HeaderComponent from "../../src/components/HeaderComponent.vue";
    import FooterComponent from "../../src/components/FooterComponent.vue";
    import ArticleComponent from "../../src/components/ArticleComponent.vue";
    import ServiceComponent from "@/components/ServiceComponent.vue";

    import { getArticle } from "../jscore/init.js";
    import { getProfileImageUrl } from "../jscore/ImageHandler.js";

    export default {
    name: "ArticleComponent",

    components: {HeaderComponent, FooterComponent, ServiceComponent},
    data() {
        return {
            article: {},
            indice: 'Blog',
            postSlug: '',
        };
    },

  
        mounted(){
            this.fetchArticle();
        },

        created() {
            // Access route params
            this.postSlug = this.$route.params.slug;

        },

        methods: {
            fetchArticle(){
                getArticle(this.postSlug)
                .then(response => {
                    this.article = response.data.blog;
                    console.log(this.article);
                })
                .catch(error => {
                    console.log(error);
                });
            },

            //image handler
            handleImage(path){
                const image = getProfileImageUrl(path);
                return image;
            },
        }
};
    
</script>

<style>

</style>