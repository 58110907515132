<template>
	<div>
		<div class="bg-cover bg-center bg-no-repeat bg-white" style="background-image: url('https://cecosarl.com/images/hero-carousel-4.jpg')">
			<div class="w-full h-100vh bg-black bg-opacity-50  justify-between p-5">
				
				<div class="mx-auto flex px-2 justify-between items-center text-white">
					<div class="container flex justify-between items-center  relative bg-cover bg-center">
						
						<!-- Logo -->
						<div class="flex items-center">
							<router-link to="/">
								<h2 class="font-bold xs:text-2xl md:text-4xl text-white">CECO Sarl</h2>
							</router-link>
						</div>

						<!-- Menu -->
						<nav class="xs:hidden lg:flex sm:ml-48 space-x-5 text-white">
							<router-link to="/" class="hover:text-custom-yellow">Accueil</router-link>
							<router-link :to="{ name: 'service'}" class="hover:text-custom-yellow">Services</router-link>
							<router-link :to="{ name: 'blogs'}" class="hover:text-custom-yellow">Blog</router-link>
							<router-link :to="{ name: 'project'}" class="hover:text-custom-yellow">Projets</router-link>
							<router-link :to="{ name: 'about'}" class="hover:text-custom-yellow">A propos</router-link>
							<router-link :to="{ name: 'contact'}" class="hover:text-custom-yellow">Contact</router-link>
						</nav>
						
					</div>
					<div class="mx-2">
						<!-- Search icon --> 
						<div class="relative flex space-x-8 text-white">
							<button v-if="!showMenu" @click="menuModal" class="focus:outline-none">
								<i class="fas fa-bars"></i>
							</button>
						</div>
					</div>
				</div>
				<div v-if="showMenu" class="absolute border-blue-500 shadow-md bg-black bg-opacity-20 text-white z-10 right-0 xs:w-36 md:w-62 px-5 px-5 p-2 md:py-5 h-auto bg-white top-0">
					<div class="flex">

						<div class="">
							<button v-if="showMenu" class="focus:outline-none" @click="menuModal" style="color:#DC3545;">
								<i class="fa fa-xmark"></i> 
							</button>
						</div>
					</div>
					
					<ul class="mt-5">
						<li class="mt-4  ">
							<router-link to="/" class=" hover:text-custom-yellow">Accueil</router-link>
						</li>
						<li class="mt-4 ">
							<router-link :to="{ name: 'service'}" class="hover:text-custom-yellow">Services</router-link>
						</li>
						
						<li class="mt-4 ">
							<router-link :to="{ name: 'project'}" class="hover:text-custom-yellow">Projets</router-link>
						</li>
						
						<li class="mt-4 ">
							<router-link :to="{ name: 'blogs'}" class="hover:text-custom-yellow">Blog</router-link>
						</li>

						<li class="mt-4 ">
							<router-link :to="{ name: 'about'}" class=" hover:text-custom-yellow">A propos</router-link>
						</li>
						<li class="mt-4 ">
							<router-link :to="{ name: 'contact'}" class="hover:text-custom-yellow">Contact</router-link>
						</li>
					</ul>

					

				</div>  

					<div class="flex item-center w-full mt-44">
						<h2 class="mx-auto font-bold xs:text-2xl md:text-4xl text-white">CECO Sarl</h2>
					</div>
					<div class="flex item-center w-full mt-5">

						<ol class="mx-auto text-white w-96">
							<li> <span class="">{{ "Bienvenue chez CECO Sarl, votre partenaire de confiance pour la construction etl'ingénierie civile. Ensemble, nous bâtissons des projets d\'exception qui façonnent l\'avenir de votre communauté" }}</span></li>
						</ol>
					</div>

					<div class="flex item-center w-full mt-24">
						<a href="#construction" class="mx-auto font-bold text-md text-white bg-custom-yellow rounded-full p-2 w-48 text-center hover:cursor-pointer">Voir plus<i class="fas fas-arrow-right"></i> </a>
					</div>
			</div>
		</div>

		<div class="w-full absolute mb-24" id="construction">
		
			<ConstructionComponent></ConstructionComponent>

			<ServiceComponent></ServiceComponent>

			<AdvantageComponent></AdvantageComponent>

			<ProjectComponent></ProjectComponent>

			<TestimonyComponent></TestimonyComponent>

			<ArticlesComponent></ArticlesComponent>
			

			<footer-component></footer-component>
			

		</div>


	</div>
	
</template>
<script>
/* eslint-disable */

import FooterComponent from '../components/FooterComponent.vue';
import ServiceComponent from '../components/ServiceComponent.vue';
import AdvantageComponent from '../components/AdvantageComponent.vue';
import ProjectComponent from '../components/ProjectComponent.vue';
import TestimonyComponent from '../components/TestimonyComponent.vue';
import ArticlesComponent from '../components/ArticlesComponent.vue';
import ConstructionComponent from '../components/ConstructionComponent.vue';


export default {
  name: "HomeView",

  components:{
	FooterComponent,
	ServiceComponent,
	AdvantageComponent,
	ProjectComponent,
	TestimonyComponent,
	ArticlesComponent,
	ConstructionComponent,
  },

  data() {
    return {
      showMenu: false,
      items: [
        { imageUrl: "https://cecosarl.com/images/home/hero-carousel-3.jpg" },
        { imageUrl: "https://cecosarl.com/images/home/hero-carousel-2.jpg" },
        { imageUrl: "https://cecosarl.com/images/home/hero-carousel-3.jpg" },
        { imageUrl: "https://cecosarl.com/images/home/hero-carousel-4.jpg" },
        { imageUrl: "https://cecosarl.com/images/home/hero-carousel-5.jpg" },


      ],

      showImage1: false,
      showImage2: false,
      showImage3: false,

      currentIndex: 0,

	  items2: [
        {
          title1: 'Design',
          content1: [
            "I don’t know what else to say, this is something that you haven’t seen before. Unique design, impressive & outstanding support.",
          ],
          title2: 'Advertising',
          content2: [
            "This is something that you haven’t seen before. Unique design, impressive & outstanding support. I don’t know what else to say,",
          ],
          title3: 'Design',
		  content3: [
            "I don’t know what else to say, this is something that you haven’t seen before. Unique design, impressive & outstanding support.",
          ],
          title4: 'Advertising',
          content4: [
            "This is something that you haven’t seen before. Unique design, impressive & outstanding support. I don’t know what else to say,",
          ]
        }
      ],
      autoplayInterval: null
    };
  },

  created() {
    this.startAutoplay();
  },

  methods: {
    menuModal() {
      this.showMenu = !this.showMenu;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.items.length) % this.items.length;
    },

	startAutoplay() {
      this.autoplayInterval = setInterval(this.nextSlide, 4000); // Change slide every 5 seconds (adjust as needed)
    },
    stopAutoplay() {
      clearInterval(this.autoplayInterval);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    }
  }
};
</script>

<style>
.carousel-item {
  transition: opacity 0.5s ease-in-out;
  flex: 0 0 100%;

}
.hidden {
  opacity: 0;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

</style>