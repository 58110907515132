<template>
    <div class="px-5 md:px-24 py-10 bg-gray-100">
					
        <div class="flex items-center font-bold xs:text-2xl md:text-4xl">
            <h2 class="mx-auto relative border-b-4 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Témoignages</h2>
        </div>
        <div class="flex items-center mt-5">
            <p class="mx-auto relative">Ce que nos clients et partenaires disent</p>
        </div>
            
        <div class="carousel xs:mt-5 md:mt-0">
            <div class="carousel-inner" :style="{ transform: `translateX(${currentIndex * -100}%)` }">
              <div v-for="(item, index) in testimonials" :key="index" class="carousel-item flex items-center">
                  <div class="xs:mt-5 md:mt-14 w-full md:w-3/4 mx-auto">
                      <div class="bg-white rounded-lg">
                          <div class='md:flex gap-5 p-5'>
                              <div class="md:w-1/4">
                                <img class="shadow-2xl border-4 border-white rounded-lg" :src="handleImage(item.image)">
                              </div>
                              <div class="md:w-3/4 ml-5 xs:mt-5 md:mt-0">
                                  <div class="xs:text-xl md:text-2xl  border-b-2 border-custom-yellow pb-5 font-bold text-gray-700">
                                      {{ item.name }}
                                  </div>
                                  <div class="font-bold text-gray-700">
                                    <p class="mt-5 xs:text-xs md:text-xl text-gray-500">"{{ item.description }}"</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

            </div>
        </div>


    </div>
</template>

<script>
/* eslint-disable */
import { getTestimonials } from "../jscore/init.js";
import { getProfileImageUrl } from "../jscore/ImageHandler.js";

export default {
  name: "TestimonyComponent",

  data() {
    return {
      showMenu: false,
      items: [
        { imageUrl: "https://cecosarl.com/images/home/hero-carousel-1.jpg" },
        { imageUrl: "https://cecosarl.com/images/home/hero-carousel-2.jpg" },
        { imageUrl: "https://cecosarl.com/images/home/hero-carousel-3.jpg" },
      ],

      showImage1: false,
      showImage2: false,
      showImage3: false,

      currentIndex: 0,

	    testimonials: [],
      autoplayInterval: null
    };
  },

  created() {
    this.startAutoplay();
    this.fetchTestimonials();
  },

  methods: {

    fetchTestimonials() {
      getTestimonials()
        .then(response => {
          this.testimonials = response.data.testimonial;
        })
        .catch(error => {
          console.log(error);
        });
    },

    menuModal() {
      this.showMenu = !this.showMenu;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.items.length) % this.testimonials.length;
    },

	  startAutoplay() {
      this.autoplayInterval = setInterval(this.nextSlide, 4000); // Change slide every 5 seconds (adjust as needed)
    },
    stopAutoplay() {
      clearInterval(this.autoplayInterval);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },

    //image handler
    handleImage(path) {
      const image = getProfileImageUrl(path);
      return image;
    }
  },
}

</script>

<style>

</style>