<template>
    <div class="px-5 md:px-10 md:px-10 py-14 w-full">
        <div class="w-full">
            
            
            <div class="px-5 md:px-10">
                <div class="md:flex flex-stretch gap-5 justify-between">
                    <div class="md:w-2/4 md:p-5" id="getStarted">
                        <img class="w-full md:h-90vh" src="https://cecosarl.com//images/advantages.jpg" alt="Avantages de travailler avec CECO">
                    </div>
                    <div class="md:w-2/4 md:p-5">
                        <div class="flex items-center xs:mt-5 md:mt-0 font-bold xs:text-2xl md:text-4xl">
                            <h2 class="mx-auto relative">Avantages de travailler avec CECO Sarl</h2>
                        </div>
                        <div class="flex items-center mt-2">
                            <h2 class="w-24 relative border-b-4 border-custom-yellow"></h2>
                        </div>
                        <p class="mt-5">Travailler avec CECO Sarl vous fait béneficier de divers Avantages, notamment:</p>

                        <div class="w-full mt-5 text-gray-600 pb-2 border-b border-gray-100">
                            <div class="flex w-full">
                                <div class="">
                                    <!-- <img src="https://cecosarl.com//images/sun.png" class="shadow-xl rounded p-2"> -->
                                </div>
                                <div>
                                    <h2 class="font-bold hover:text-custom-yellow text-xl">Expertise reconnue dans la construction et l'ingénierie</h2>
                                </div>
                            </div>
                            <!-- <p class="mt-3 text-sm">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque Solutions innovantes </p> -->
                        </div>

                        <div class="w-full mt-5 text-gray-600 pb-2 border-b border-gray-100">
                            <div class="flex w-full">
                                <div class="">
                                    <!-- <img src="https://cecosarl.com//images/sun.png" class="shadow-xl rounded p-2"> -->
                                </div>
                                <div>
                                    <h2 class="font-bold hover:text-custom-yellow text-xl">Engagement envers la qualité et la sécurité</h2>
                                </div>
                            </div>
                            <!-- <p class="mt-3 text-sm">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque Solutions innovantes </p> -->
                        </div>

                        <div class="w-full mt-5 text-gray-600 pb-2 border-b border-gray-100">
                            <div class="flex w-full">
                                <div class="">
                                    <!-- <img src="https://cecosarl.com//images/sun.png" class="shadow-xl rounded p-2"> -->
                                </div>
                                <div>
                                    <h2 class="font-bold hover:text-custom-yellow text-xl">Solutions innovantes adaptées à vos besoins</h2>
                                </div>
                            </div>
                            <!-- <p class="mt-3 text-sm">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque Solutions innovantes </p> -->
                        </div>

                        <div class="w-full mt-5 text-gray-600">
                            <div class="flex w-full">
                                <div class="">
                                    <!-- <img src="https://cecosarl.com//images/sun.png" class="shadow-xl rounded p-2"> -->
                                </div>
                                <div>
                                    <h2 class="font-bold hover:text-custom-yellow text-xl">Formulaire de demande de devis</h2>
                                </div>
                            </div>
                            <!-- <p class="mt-3 text-sm">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque Solutions innovantes </p> -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
  name: "AdvantageComponent",

  data() {
    return {
    };
  },

  methods: {

  }
}

</script>

<style>

</style>