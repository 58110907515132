<template>
    <div class="flex items-center">
        <div class="mx-auto px-44 py-44">
            <h1 class="text-red-500 text-4xl">404 - Page Not Found</h1>
            <p class="mt-10">Sorry, the page you are looking for does not exist.</p>
            <div class="mt-10">
                <router-link to="/" class="bg-custom-yellow text-white rounded-full px-2 py-2">Go to Home <i class="fas fa-arrow-right"></i> </router-link>
            </div>
        </div>
    </div>

  </template>
  
  <script>
  export default {
    name: 'NotFoundView'
  };
  </script>
  
<style>
</style>

  