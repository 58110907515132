<template>
    <div>
        <Header-component :titleMessage="indice"></Header-component>

        <div class="w-full px-5 py-5 md:px-10 md:py-14">	
            <div class="md:flex w-full gap-5 justify-between md:px-24">
				<div class="md:w-2/4 p-10 bg-white text-gray-600 shadow-lg text-center">
					<i class="fas fa-location text-4xl text-custom-yellow border rounded-full p-3 border-dashed border-custom-yellow"></i>
					<p class="font-bold text-md mt-5">Notre Adresse</p>
					<p class="mt-1">RDC, Nord-Kivu, Goma</p> 
					<p class="mt-1">Katindo. Ref Salle des fetes LG Muvitho</p>
				</div>

				<div class="md:w-1/4 xs:mt-5 md:mt-0 p-10 bg-white text-gray-600 shadow-lg text-center">
					<i class="fas fa-message text-4xl text-custom-yellow border rounded-full p-3 border-dashed border-custom-yellow"></i>
					<p class="font-bold text-md mt-5">Adresse Email</p> 
					<p class="mt-1">cecosarl@gmail.com</p>
					<p class="mt-1">alphonseben19@yahoo.fr</p>

				</div>

				<div class="md:w-1/4 xs:mt-5 md:mt-0 p-10 bg-white text-gray-600 shadow-lg text-center">
					<i class="fas fa-phone text-4xl text-custom-yellow border rounded-full p-3 border-dashed border-custom-yellow"></i>
					<p class="font-bold text-md mt-5">Appelez-Nous</p>
					<p class="mt-1">+243 897 261 065</p>
					<p class="mt-1">+243 972 369 552</p>

				</div>
            </div>


			<div class="md:flex w-full mt-10 gap-5 justify-between md:px-24">
				<div class="md:w-2/4 p-5 bg-white text-gray-600 shadow-lg text-center">
					<div style="overflow:hidden;resize:none;max-width:100%;width:500px;height:400px;">
						<div id="google-maps-display" style="height:100%; width:100%;max-width:100%;">
							<iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/search?q=muvitho&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
						</div>
					</div>
				</div>
				<div class="md:w-2/4 xs:mt-5 md:mt-0 p-5 bg-white text-gray-600 shadow-lg">
					<form class="py-5 w-full px-5">
						<div class="w-full grid grid-cols-2 justify-between gap-3">
							<div class="w-full">
								<input type="text" placeholder="Votre Nom" class="w-full border py-2 px-2">
							</div>
							<div class="w-full">
								<input type="email" placeholder="Votre Email" class="w-full border py-2 px-2">
							</div>
						</div>
						<div class="w-full mt-5">
							<input type="text" placeholder="Sujet" class="w-full border py-2 px-2">
						</div>

						<div class="w-full mt-5">
							<textarea placeholder="Message" class="w-full h-36 border py-2 px-2"></textarea>
						</div>

						<div class="w-full mt-5 items-left">
							<button type="submit" class="rounded bg-custom-yellow text-white py-2 px-2">Envoyer</button>
						</div>
					</form>
				</div>
            </div>

        </div>

		<ArticlesComponent></ArticlesComponent>

		<div class="mt-10">
			<footer-component></footer-component>
		</div>
    </div>
</template>

<script>
/* eslint-disable */

import HeaderComponent from "../../src/components/HeaderComponent.vue";
import FooterComponent from "../../src/components/FooterComponent.vue";
import ArticlesComponent from "../../src/components/ArticlesComponent.vue";

export default {
  name: "ContactView",
  components: { HeaderComponent, FooterComponent, ArticlesComponent },

  data(){
	return{
		indice: 'Contact'
	}
  }
};
</script>

<style>
</style>